.pages{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 60px;
}

.pages > p {
    padding: 10px;
    cursor: pointer;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .5;
}

.active {
    background-color: #CFDCE5;
    opacity: 1;
}

.pages > p.active {
    opacity: 1;
}