.container {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 15px;
}

.container:hover {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.bold {
    font-weight: bold;
}