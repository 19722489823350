.container {
    padding: 10px;
}

.checked {
    background-color: #BFEAB6;
}

.right {
    color: #2D9BF0;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
}