
.container{
  display: flex;
  flex-direction: column;
  font-size: 10px;
  flex: 1;
}

.section{
  display: flex;
  min-height: 10px;
  min-width: 100px;
  justify-content: space-between;
}

.section>p{
  flex: 1;
  border: solid 1px black;
  max-width: 0;
}

.marker>p{
  height: 20px;
  transform: translate(-3px, 3px);
}

.marker{
  display: flex;
  flex-direction: column;
  height: 5px;
}