.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropbtn {
    background: none;
    padding: 10px;
    font-size: 16px;
    border: solid 1px black;
    cursor: pointer;
    aspect-ratio: 5;
    min-width: 100px;
    text-align: center;
  }

  
  .dropdownContent { /* Use camelCase in CSS Modules */
    position: absolute;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
    list-style: none;
    padding: 0;
    margin: 0;
    top: 0;
    width: 100%;
    background: white;
    transform: translateY(25%);
    right: 0;
  }
  
  .dropdownItem { /* Use camelCase in CSS Modules */
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    border-radius: 0;
  }
  
  .dropdownItem:hover {
    background-color: #ddd;
  }
  
  .show {
    display: block;
  }

  .dropbtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .arrow {
    margin-left: 8px;
    transition: transform 0.3s ease;
  }
  
  .open .arrow {
    transform: rotate(180deg);
  }