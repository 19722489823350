.tagsContainer{
    flex: 1;
    display: flex;
    border: solid 1px rgb(215, 215, 215);
}

.tagsContainer > p{
    padding: 10px;
}

.outer {
    align-items: center;
    display: flex;
    gap: 20px;
    padding: 0 40px;
}