.container {
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    z-index: 999;
}

.inputContainer {
    display: flex;
    width: 100%;
    box-shadow: 0px 0px 5px 0px #efefef;
    padding: 20px;
    background-color: #efefef;
    border-radius: inherit;
    gap: 20px;
    height: 50px;
    align-items: center;
}

.resultsContainer {
    display: flex;
    width: inherit;
    z-index: 999;
    background-color: #efefef;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
}

.icon > img {
    width: 25px;
}

.input {
    display: flex;
    height: 50px;
    border-radius: inherit;
    border: none;
    font-size: 1rem;
    background-color: inherit;
    color: #333;
    align-items: center;
}

.input::placeholder {
    color: rgba(0,0,0,0.5)
}

.input:focus{
    outline: none;
}

.results {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 300px;
    width: 100%;
    border-radius: inherit;
    padding: 10px;
    background-color: #ebebeb;
    overflow-y: scroll;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}