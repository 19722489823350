.container {
    width: 350px;
    height: auto;
    background-color: #24394F;
    box-shadow: 0px 0px 5px 0px #24394F;
    position: fixed;
    right: 10px;
    bottom: 100;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: scroll;
    scrollbar-color: #141f2b #213248;
    z-index: 9999;
    overflow: hidden;
}

.header {
    display: flex;
    align-items: center;
    gap: 15px;
    color: #fff;
    padding: 15px;
}

.header > p {
    padding: 10px;
    border-radius: 5px;
    opacity: .5;
    cursor: pointer;
}

.header > p.active {
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 1;
}

.body {
    overflow-y: scroll;
}