.container {
    padding: 10px;
    width: 250px;
    background-color: #52BFA2;
    border: none;
    color: #fff;
    font-size: 1.1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: all ease-in-out 200ms;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.container:hover {
    transform: scale(1.05);
}

.small {
    width: 160px;
    font-size: .8rem;
}

.icon {
    height: 50px;
    width: 50px;
}

.icon > img {
    height: 35px;
}

.disabled {
    background-color: #ddd !important;
    color: #fff !important;
}