.container {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .skeleton {
    background: linear-gradient(90deg, #eee, #f9f9f9, #eee);
    animation: leftToRight 1.5s infinite reverse;
    background-size: 200%;
  }
  
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .info {
    margin-left: 10px;
  }
  
  .text {
    width: 1200px;
    height: 16px;
    border-radius: 4px;
    margin-bottom: 3px;
  }
  
  @keyframes leftToRight {
    0% {
      background-position: -100% 0;
    }
  
    100% {
      background-position: 100% 0;
    }
  }