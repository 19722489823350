.asterisk {
    color: red;
    margin-left: 4px;
    font-size: 16px; /* Adjust as needed */
  }
  
  .floatingLabelInput {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #fff;
    -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  }

  .floatingLabelInput > * {
    flex: 1;
  }
  
  .input {
    width: 100%;
    font-size: 14px !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    height: 75px;
  }

  .input:focus {
    outline: none;
  }

  .input::placeholder {
    color: rgba(0,0,0,0.25);
  }
  
  .floatingLabel {
    padding: 5px 10px;
    font-size: 10px; /* Smaller size to distinguish from placeholder */
    color: #414BB2; /* Styled for visibility */
    pointer-events: none; /* Prevent interactions with the label */
    transition: color 0.2s ease; /* Optional: Add color transition for focus */
  }
  