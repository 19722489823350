

.actionButton{
    border: none;
    background: white;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    position: relative;
}


.actionButton:hover{
    background: rgb(242, 242, 242);
}

.numberSelected{
    border-radius: 100%;
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: white;
}

.container{
    background: white;
    align-items: center;
}

.right{
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 10px;
}
