@keyframes shake {  
    0% { transform: translateX(0); }  
    25% { transform: translateX(-5px); }  
    50% { transform: translateX(5px); }  
    75% { transform: translateX(-5px); }  
    100% { transform: translateX(0); }  
  }  
    
  @keyframes pulse {  
    0% { transform: scale(1); }  
    50% { transform: scale(1.05); }  
    100% { transform: scale(1); }  
  }  
    
  .createInput {  
    flex: 1;  
    display: flex;  
    flex-direction: column;  
    justify-content: space-around;  
    padding: 30px;  
    background-color: rgb(242, 242, 242);  
    transition: all 0.3s ease;  
  }  
    
  .createButton {  
    border: none;  
    background: white;  
    padding: 25px;  
    display: flex;  
    flex-direction: column;  
    align-items: center;  
    gap: 5px;  
  }  
    
  .createButton:hover {  
    background: rgb(242, 242, 242);  
  }  
    
  .warning {  
    position: absolute;  
    color: red;  
    top: 5px;  
    left: 25px;  
  }  
    
  .shake {  
    animation: shake 0.5s;  
  }  
