.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 40px;
    padding: 40px 20px;
    flex: 1 1;
}

.header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px;
}

.body {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: stretch;
}