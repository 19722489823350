.dropdown {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .dropbtn {
    background: none;
    padding: 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    min-width: 100px;
    text-align: center;

  }
  
  .dropdownContent { /* Use camelCase in CSS Modules */
    padding: 20px;
    background: white;
  }
  
  .dropdownItem:hover {
    background-color: #ddd;
  }
  
  .show {
    display: block;
  }

  .dropbtn {
    /* Existing styles */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .arrow {
    margin-left: 8px;
    transition: transform 0.3s ease; /* Smooth transition for rotating the arrow */
  }
  
  .open .arrow {
    transform: rotate(180deg); /* Rotate the arrow when dropdown is open */
  }