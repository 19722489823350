
.outerContainer{
    display: flex;
    flex-direction: column;
    flex: 1;
}

.top {
    display: flex; 
    flex-direction: column;
    gap: 20px;
    width: 100%;
    justify-content: center;
    color: #000;
    padding: 40px 20px;
    font-size: .8rem;
}

.infoContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.questionnaire>div{
    display: flex;
    flex-direction: column;
    gap: 20px;
}