.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
    padding: 15px 20px;
    background-color: #FFF;
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    transition: all ease-in-out 200ms;
}

.container > * {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.status {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.status>* {
    display: flex;
    flex: 1;
}

.statusLeft {
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: bold;
}

.statusRight {
    justify-content: flex-end;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerLeft {
    display: flex;
    align-items: center;
    gap: 10px;
}

.footerRight {
    display: flex;
    align-items: center;
    gap: 10px;
}

.info {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: flex-start;
    font-size: .9rem;
}

.infoLeft {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.infoRight {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.participants {
    text-decoration: underline;
    color: #2D9BF0;
    cursor: pointer;
}