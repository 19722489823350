@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

a {
  text-decoration: none;
  color: inherit;
}

#wrapper,
#root {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: stretch;
  background-color: #FBFBFB;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.25rem;
}

textarea{
  margin: 0;
  padding: 10px;
  font-size: 0.8rem;
}

textarea {
  padding: 10px; /* Add padding inside the textarea */
  border-radius: 5px; /* Rounded corners */
  resize: vertical; /* Allow vertical resizing only */
  font-family: Arial, sans-serif; /* Use a clean font */
  font-size: 14px; /* Readable font size */
  line-height: 1.5; /* Space out lines for readability */
  color: #333; /* Dark grey text */
  background-color: #FFF; /* Light background color */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for focus */
}

textarea:focus {
  border-color: #66afe9; /* Blue border on focus */
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.5); /* Blue glow on focus */
  outline: none; /* Remove default outline */
}

.wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
}

#alerts {
  display: flex;
  flex-direction: column;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 999999;
}

@media all and (display-mode: standalone) {
  #wrapper,
  #root {
    flex-direction: column;
  }
}

input {
  border: none;
}

button {
  border: none;
}