.container {
    flex: 1;
    height: 100vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.top-nav {
    height: 80px;
    display: flex;
    background-color: #fff;
    align-items: center;
    padding: 0px 20px;
    justify-content: space-between;
    z-index: 999;
}

.top-nav__right {
    display: flex;
    align-items: center;
    gap: 5px;
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
    max-height: calc(100vh - 80px);
}



