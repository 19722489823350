.tab {
    padding: 10px 0px;
    cursor: pointer;
    font-size: 1.25rem;
}

.active {
    border-bottom: 2px solid #414BB2;
    color: #414BB2;
    font-weight: 600;
}