.container {    
    display: flex;
    cursor: pointer;
}


li {
    display: flex;
    gap: 20px;
    font-size: 1rem;
    padding: 10px;
    align-items: center;
    border-radius: 10px;
    transition: all ease-in-out 100ms;
}


li.active > span {
    font-weight: bold;
}

li > img {
    width: 20px;
}

li > span {
    display: none;
}

li:hover {
    animation: colourTextHover 200ms 1 normal forwards;
}

@keyframes colourTextHover {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@media all and (display-mode: standalone) {
    .container {
        flex: 1;
    }
}