.progressBarContainer {
    width: 100%;
    background-color: #e0e0e0; /* Grey background for empty progress */
    overflow: hidden;
    height: 5px;
}

.progressBarFill {
    background-color: #ff4d4d; /* Red color for the filled progress */
    height: 100%;
    transition: width .5s ease-in-out; /* Smooth transition for fill */
}

.green {
    background-color: green;
}

.yellow {
    background-color: #EAB33C;
}
