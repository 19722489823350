.container {
    position: relative;
}

.container>.count {
    position: absolute;
    top: 0;
    right: 0;
    height: 18px;
    width: 18px;
    border-radius: 9px;
    background-color: #E25A74;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .7rem;
    animation: pop 750ms 500ms 2 forwards;
}

@keyframes pop {
    0% {
        transform: none;
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: none;
    }
}