.container {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: stretch;
    gap: 20px;
    padding: 10px;
    border-left: 8px solid rgba(0, 0, 0, 0);
    cursor: pointer;
    justify-content: space-around;
    transition: all ease-in-out 250ms;
}

.container > h3 {
    font-weight: 500;
}

.active {
    border-left: 8px solid #0A66C2;
}

.criteriaItems {
    display: flex;
    flex-direction: column;
    transition: all ease-in-out 250ms;
}

.titleContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item{
    font-size: 13px;
}

.item:hover {
    transition: all ease-in-out 200ms;
}