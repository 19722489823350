.container {
    height: 100px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px
}

.circle {
    height: 15px;
    width: 15px;
    border-radius: 7.5px;
}

.mesmaRed {
    background-color: #E25A74;
    animation: scale 1000ms 300ms infinite forwards;
}

.mesmaYellow {
    background-color: #EAB33C;
    animation: scale 1000ms 500ms infinite forwards;
}

.mesmaGreen {
    background-color: #52BFA2;
    animation: scale 1000ms 700ms infinite forwards;
}


@keyframes scale {
    0% {
        transform: none;
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: none;
    }
}