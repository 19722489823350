.container input,
.container select {
  padding: 10px 15px 15px 15px;
  background-color: #fff;
  flex: 1;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  font-size: 14px;
}


.container > input:focus,
.container > select:focus,
.container > textarea:focus {
  outline: none;
}