.addItem{
    background: white;
}

.addItem:hover{
    background: rgb(242, 242, 242);
}

.inputCreate{
    background: rgb(242, 242, 242);
    padding: 15px;
}