.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}

.bottom {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    position: relative;
    align-items: stretch;
}

.left {
    flex: 1;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    overflow-y: scroll;
}

.right {
    display: flex;
    width: 300px;
    background-color: #FFF;
    right: 0;
    z-index: 998;
    padding-top: 10px;
    min-height: calc(100vh - 80px);
}

.titleContainer{
    width: 100%;
    display: flex;
    max-height: 40px;
    padding-left: 20px;
}

.title{
    flex: 3;
}

.gear{
    max-width: 30px;
    margin-right: 20px;
}

.arrow{
    margin: 0;
    max-width: 30px;
}

.gradesInnerContainer{
    flex: 1;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-direction: column;
}

.gradesContainer{
    display: flex;
    flex-direction: row;
    gap: 50px;
    padding: 30px 20px;
}

.kpiContainer{
    background-color: #CFDCE5;
    display: flex;
    padding: 20px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
