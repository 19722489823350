.container {
    background-color: #FFF;
    -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    height: auto;
    transition: height ease-in-out 200ms;
}

.top {
    max-height: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    cursor: pointer;
}

.body {
    padding: 0px 20px 20px 20px;
}

.arrow {
    transform: none;
    transition: transform ease 0.3s;
    cursor: pointer;
}

.arrow.expanded {
    transform: rotate(180deg);
}