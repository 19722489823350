.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.6);
}

.popup {
    display: flex;
    background-color: #fff;
    align-items: stretch;
    flex-direction: column;
    width: 60%;
    height: 80%;
    overflow-y: scroll;
    opacity: 0;
    transform: translateY(100%);
    animation: slideUp 150ms 100ms 1 forwards;
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}