.tour-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
}

.tour-button-container:hover {
    transform: scale(1.1);
}

.tour-button-container > img {
    width: 30px;
}

.reactour__mask {
    opacity: .85 !important;
}

.reactour__close-button {
    right: 10px !important;
    top: 10px !important;
}

.reactour__popover {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px;
    padding: 25px !important;
    align-items: center;
    top: 10px !important;
    left: 5px !important;
}

.reactour__popover > span {
    display: none;
    background-color: red !important;
}

.reactour__popover button {

    &[aria-label="Go to next step"] {
        background-color: #24394F !important;
        display: flex !important;
        padding: 10px !important;
        content: "Next" !important;
        border-radius: 10px;
        color: #fff;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &[aria-label="Go to next step"]::after {
        content: "Next";
    }

    
    &[aria-label="Go to prev step"] {
        background-color: #24394F !important;
        display: flex !important;
        padding: 10px !important;
        content: "Next" !important;
        border-radius: 10px;
        color: #fff;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    &[aria-label="Go to prev step"]::after {
        content: "Go back";
    }

}

.reactour__popover button svg {
    display: none;
}