.container {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    cursor: pointer;
}

.checked {
    background-color: #29AD2D;
}