.container {
    min-height: 40px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    z-index: 999;
    background-color: #ebebeb;
    color: #333;
}
.container > .left > p {
    font-size: 1.2rem;
}

.container:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.imageContainer {
    height: 60px;
    width: 80px;
    border-radius: 10px;
}

.imageContainer .image {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.left,
.right {
    display: flex;
    align-items: center;
}

.left {
    gap: 20px;
}

.right {
    width: 60px;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.right > img {
    width: 20px;
}

.likes {
    font-size: 1.2rem;
    color: #24394F;
}