.container {
    display: flex;
    flex-direction: column;
    width: 70px;
    background-color: #FFF;
    transition: all ease-in-out 200ms;
    padding-top: 0;
    gap: 10px;
    height: 100vh;
}

.header {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header>img {
    width: 50px;
}

.body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    align-items: stretch;
    height: 100%;
    justify-content: space-between;
    font-size: 16px;
}

.body>li:nth-child(5),
.body>li:nth-child(6),
.body>li:nth-child(7) {
    justify-self: flex-end;
}

.bottomNav {
    padding-bottom: 20px;
}

@media all and (display-mode: standalone) {
    .container {
        width: 100vw;
        height: 100px;
        padding: 0;
        position: fixed;
        display: flex;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        transition: all ease-in-out 200ms;
        border-radius: 50px 50px 0px 0px;
        z-index: 1000;
    }

    .container>.body li>span,
    .container:hover>.body li>span {
        display: none;
    }

    .header {
        display: none;
    }

    .body {
        flex-direction: row;
        gap: 10px;
        height: 100px;
        align-items: center;
    }

    .topNav {
        flex-direction: row;
        display: flex;
        width: 100%;
        gap: 10px;
    }

    .topNav>li {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 60px;
    }

    .bottomNav {
        display: none;
    }
}



@media only screen and (min-width: 800px) {

    .container:hover {
        width: 180px;
    }

    .container:hover>.body li {
        justify-content: flex-start;
    }

    .container:hover>.body li>span {
        flex: 1;
        display: flex;
        align-items: center;
    }
}