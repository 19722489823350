.relative{
    position: relative;
}

.topRight{
    position: absolute;
    top: 0;
    right: 0;
}

.column, .column1, .column2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.column1{
    gap: 20px;
}

.column1{
    gap: 20px;
}

.column2{
    gap: 40px;
}

.row, .rowSpan, .rowSpan20, .rowSpan40, .row1, .row2, .rowHalf, .rowSpanHalf{
    display: flex;
    flex-direction: row;
    flex: 1;
}

.rowHalf, .rowSpanHalf{
    gap: 10px;
}

.row1, .rowSpan20{
    gap: 20px;
}

.row2, .rowSpan40{
    gap: 40px;
}

.rowReverse{
    display: flex;
    flex-direction: row-reverse;
}

.columnReverse{
    flex-direction: column-reverse;
}

.rowSpan, .rowSpan20, .rowSpan40{
    justify-content: space-between;
}


.alignFlexEnd {
    align-items: flex-end;
}

.justifyFlexEnd {
    justify-content: flex-end;
}

.justifySpaceAround {
    justify-content: space-around;
}

.container{
    padding: 20px;
    width: 100%;
    height: 100%;
}

.centered{
    align-items: center;
}

.marginTop{
    margin-top: 20px;
}

.marginTop2{
    margin-top: 40px;
}

.padding10{
    padding: 10px;
}

.padding15{
    padding: 15px;
}

.padding20{
    padding: 20px;
}

.paddingLeft10{
    padding-left: 10px;
}

.paddingLeft15{
    padding-left: 15px;
}

.icon{
    width: 30px;
}

.lightBlue{
    background-color: #CFDCE5;
}

.blue{
    background-color: rgb(36, 57, 79);
}