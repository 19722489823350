.floatingLabelSelect {
    position: relative;
    -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .floatingLabelSelect > * {
    flex: 1;
  }
  
  .select {
    width: 100%;
    padding: 15px 12px 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    background-color: transparent;
    height: 60px;
  }

  
  .floatingLabel {
    padding: 5px 10px;
    font-size: 10px;
    color: #414BB2;
    pointer-events: none;
    transition: color 0.2s ease;
  }

  .select option {
    color: #000;
  }
  

  .select:invalid {
    color: rgba(0,0,0,0.25); /* Default color for the "placeholder" option */
  }
  
  .asterisk {
    color: red;
    margin-left: 4px;
    font-size: 16px; /* Adjust as needed */
  }
  